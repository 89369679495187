<template>
  <div :class="listButton ? 'table-style' : ''">
    <el-upload v-show="!disabled"
               class="upload-demo"
               :drag="isDrag"
               :data="data"
               :name="name"
               action="#"
               :headers="headers"
               :multiple="multiple"
               :file-list="disFileList"
               :show-file-list="false"
               :http-request="handleUploadFile"
               :on-success="handleUploadSuccess"
               :before-upload="handleBeforeUpload"
               :limit="fileLimit"
               :disabled="btnDisabled"
               :accept="acceptStrings"
               :on-exceed="handleExceed"
               ref="upload">
               <slot v-if="!isDrag"></slot>
      <span v-if="isDrag">
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div v-show="uploadStatus.isUploading"
           style="width: 100px">
        <!-- :percentage="parseFloat(uploadStatus.uploadPercent)" -->
        <el-progress :percentage="parseFloat(uploadStatus.uploadPercent)"
                     :width="126"></el-progress>
      </div>
      <div class="el-upload__tip"
           slot="tip"
           v-if="showTip">
        请上传 <b style="color: #f56c6c">{{ acceptString }} </b>类型文件
        <template v-if="showSize.show">,{{ showSize.size }}</template>
      </div>
    </span>
    </el-upload>
    <el-row class="detail-info-attachment"
            v-if="!hide">
      <el-row class="detail-info-attachment-list"
              v-if="!isTableStyle">
        <el-col :span="24"
                v-for="(item, index) in cResFileList"
                :key="index">
          <div class="detail-info-attachment-item"
               v-loading="item.fileLoading"
               element-loading-background="#f7f7f7a8">
            <div class="attachment-item-icon">
              <svg class="icon"
                   aria-hidden="true">
                <use :xlink:href="getIconimage(item.suffix)"></use>
              </svg>
              <div class="attachment-item-name">
                {{ index+1 }}. {{ item.fileName }}
              </div>
            </div>
            <div style="display:flex; justify-content: flex-end; align-items: center;">
            <div v-if="upInfo.length"
                 class="attachment-item-info">
              {{ upInfo[0]+': '+item.createTime }}
            </div>
            <div v-if="upInfo.length&&item.publishUserName"
                 class="attachment-item-info">
              {{ upInfo[1]+': '+item.publishUserName }}
            </div>
            <div class="attachment-item-link-download"
                 v-show="item.percentage == 100 && item.status == 'success'">
                 <i class="fileIcon el-icon-download"></i>
              <a @click="handleDownload(item)">下载</a>
            </div>
            <div class="attachment-item-link-delete"
                 v-show="
                item.percentage == 100 && !disabled && item.status == 'success'
              ">
              <i class="fileIcon el-icon-delete"></i>
              <a @click="handleDelete(item)">删除</a>
            </div>
            <div class="attachment-item-link-preview"
                 v-show="item.percentage == 100 && item.status == 'success'"
                 v-if="
                item.suffix == 'jpg' ||
                item.suffix == 'png' ||
                item.suffix == 'jpeg' ||
                item.suffix == 'doc' ||
                item.suffix == 'xlsx' ||
                item.suffix == 'ppt' ||
                item.suffix == 'xls'||
                item.suffix == 'docx'||
                item.suffix == 'pptx'||
                item.suffix == 'pdf'||
                item.suffix == 'mp4'||
                item.suffix == 'MP4'
              ">
              <i class="fileIcon el-icon-view"></i>
              <a @click="handleImagePreview(item)">预览</a>
            </div>
            <div class="attachment-item-progress"
                 v-show="item.status != 'success'">
              <el-progress type="circle"
                           :percentage="parseFloat(item.percentage)"
                           :width="40"
                           :stroke-width="3"></el-progress>
            </div>

            <div class="attachment-item-status"
                 v-show="item.status == 'success'">
              <i class="el-icon-success" />
            </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="detail-info-attachment-list"
           v-else>
        <div class="list-button-area">
          <div>
            <list-edit-btn btnText="批量下载"
                           icon="el-icon-download"
                           @click="batchDown" />
          </div>
        </div>
        <el-table :data="resFileList"
                  ref="tableRef"
                  @selection-change="selectionChange"
                  stripe
                  border
                  :header-cell-style="{
                background: '#EFF3FB',
                color: '#374351',
                borderColor: '#e4e4e4',
                'max-height':'60px'
              }"
                  :header-row-style="{
                height:'60px'
              }"
                  style="width: 100%">
          <el-table-column type="selection"
                           align="center"></el-table-column>
          <el-table-column prop="fileName"
                           label="文件名称"
                           minWidth="40%">
          </el-table-column>
          <el-table-column prop="createUserName"
                           label="创建人"
                           minWidth="20%">
          </el-table-column>
          <el-table-column prop="createTime"
                           label="创建时间"
                           minWidth="20%">
          </el-table-column>
          <el-table-column label="操作"
                           align="center"
                           minWidth="20%">
            <div slot-scope="scope">
              <div v-show="scope.row.status == 'success'">
                <simple-table-menu :mainBtn="{name: '预览', hasPermis: 'user_view'}"
                                   :menuList="[
            {
              name: '下载',

              icon: '#icon-bianji1',
              display: true
            },
            {
              name: '删除',

              icon: '#icon-shanchu',
              display: !disabled
            }
          ]"
                                   :model="scope.row"
                                   @command="operationClick" />
              </div>
              <div class=""
                   v-show="scope.row.status != 'success'">
                <el-progress :text-inside="true"
                             :stroke-width="16"
                             :percentage="scope.row.percentage"></el-progress>
              </div>
            </div>

          </el-table-column>
        </el-table>
      </div>
    </el-row>
    <el-image-viewer v-if="imagePreviewVisible"
                     :initialIndex="imagePreviewIndex"
                     :z-index="9000"
                     :on-close="handleImageViewerClose"
                     :url-list="imagePreviewList" />
    <!-- 视频播放器对话框 -->
    <el-dialog title=""
               :visible.sync="open"
               width="60%"
               :close-on-click-modal='false'
               append-to-body
               @close="closeMovieDialog">
      <el-form ref="videoForm"
               :model="videoForm"
               size="small"
               label-width="100px">
        <video-player class="video-player vjs-custom-skin"
                      ref="videoPlayer"
                      :playsinline="true"
                      :options.sync="playerOptions"
                      @ready="playerReady">
        </video-player>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import bus from '@/utils/bus'
import { getToken, getMemberToken, getSwitchToCompanyId } from '@/utils/auth'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import SparkMD5 from 'spark-md5'
import { ossStsRegister, ossStsRegisterCallback } from './upload'
import OSS from 'ali-oss'
import { Message } from 'element-ui'

export default {
  name: '',
  components: { ElImageViewer },
  props: {
    isDrag: {
      type: Boolean,
      default: true
    },
    // 是否显示上传时间，发布人等；
    upInfo: {
      type: Array,
      default: () => []
    },
    // 是否显示大小限制
    showSize: {
      type: Object,
      default: () => {
        return {
          show:false,
          size:'且大小不超4G'
        }
      }
    },
    // 是否修改文件名称
    isEditFileName: {
      type: Boolean,
      default: false
    },
    // 修改的文件夹名称
    editFileName: {
      type: String,
      default: ''
    },
    // 是否显示大小限制
    fileSize: {
      type: String,
      default: '0'
    },
    // 是否显示上传提示
    showTip: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    btnDisabled: {
      type: Boolean,
      default: false
    },
    // 上传文件类型
    acceptString: {
      type: String,
      default:'pdf,zip,rar,7z,bmp,gif,jpeg,jpg,png,avi,mov,mp4,mpeg,mpg,rm,rmvb,mp3,wav,wma,ogg,aac,txt,doc,docx,xls,xlsx,ppt,pptx,csv,txt,ftr'
      },
    // 上传文件类型
    title: {
      type: String,
      default: '附件上传'
    },
    // 上传地址
    // action: {
    //   type: String,
    //   default: () => '/api/huizhuyun-realname/realname-attachment/upload'
    // },
    // 设置上传的请求头部
    headers: {
      type: Object,
      default: () => {
        return {
          Authorization: 'Basic c2FiZXI6c2FiZXJfc2VjcmV0',
          'Blade-Auth': 'Bearer ' + getToken(),
          token: getMemberToken(),
          SwitchToCompanyId: getSwitchToCompanyId()
        }
      }
    },
    // 是否显示文件详情
    hide: {
      type: Boolean,
      default: false
    },
    // 是否在不显示文件详情的时候在上传按钮上显示上传状态
    showUploadStatus: {
      type: Boolean,
      default: false
    },
    // 获取到的文件列表
    fileList: {
      type: Array,
      default: () => {
        return []
      }
    },
     // 最大允许上传个数
     fileLimit: {
      type: Number,
      default: 99
    },
    // 文件个数显示
    limit: {
      type: Number,
      default: 500
    },
    // 上传时附带的额外参数
    data: Object,
    // 是否支持多选文件
    multiple: {
      type: Boolean,
      default: false
    },
    // 上传的文件字段名
    name: {
      type: String,
      default: 'file'
    },
    // 是否显示上传成功的提示
    isShowSuccessTip: {
      type: Boolean,
      default: true
    },
    listButton: {
      type: Boolean,
      default: false
    },
    // 用于合同相关业务，做特殊处理
    contractType: {
      type: Boolean,
      default: false
    },
    // 文件列表展示样式标志位，若为图表则传true
    isTableStyle: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    limitSize: {
      type: String,
      default: '500MB'// B KB MB GB
    },
  },
  data() {
    return {
      // 图标
      acceptStringIcon:'pdf,zip,rar,7z,bmp,gif,jpeg,png,mp4,mp3,wma,txt,doc,docx,xls,xlsx,ppt,pptx,csv,txt',
      // 视频展示弹窗-from
      videoForm: {},
      // 视频展示弹窗-显示/隐藏
      open: false,
      // 用于视频旋转方向
      directionFlag: false,
      // 视频旋转角度
      direcation: 0,
      // 组件内-文件列表展示数组
      resFileList: [],
      // el-upload组件文件列表，防止未知错误，请勿删除
      disFileList: [],
      // 优化需求：当随便点开某一条图片类型的数据预览时，支持缩放，顺逆时针旋转，上下一张。其中上下一张是在所有已上传的附件列表中，只需要过滤出图片类型的数据上下翻页，忽略非图片类型的附件。
      // 图片预览visible
      imagePreviewVisible: false,
      // 图片预览数组
      imagePreviewList: [],
      // 被预览的图片在数组中的索引。保证预览时第一张显示的是被预览的图片
      imagePreviewIndex: 0,
      // 通过图片的后缀来从附件列表中筛选出图片
      acceptImageTypeString: '.png,.tif,.jpg,.jpeg,.gif,.bmp,.svg',
      // 通过图片的后缀来从附件列表中筛选出图片
      acceptImageType: [
        '.png',
        '.tif',
        '.jpg',
        '.jpeg',
        '.gif',
        '.bmp',
        '.svg'
      ],
      // 预览可支持的文件格式
      officePreviewType: '.doc,.xlsx,.ppt,.xls,.xlsx,.docx,.pptx',
      // 视频播放器设置
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        sources: [
          {
            type: 'video/mp4', //这里的种类支持很多种：基本视频格式、直播、流媒体等
            src: null //url地址 "../../static/vedio/test1.mp4"
          }
        ],
        poster: null, //你的封面地址 "../../static/vedio/test.jpg"
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true //全屏按钮
        }
      },
      // 结合hide、showUploadStatus、resFileList中文件上传的状态判断是否显和更新上传状态
      uploadStatus: {
        uploadPercent: 0,
        isUploading: false
      },
      /**
       * table样式附件列表属性
       */
      selectionData: []
    }
  },
  watch: {
    fileList(val) {
      this.getListInit()
    },
    // 用于监听视频旋转方向，如果方向是360的时候，自动恢复默认值0
    direcation: {
      handler(val) {
        if (val == 360) {
          this.direcation = 0
        }
      },
      deep: true
    },
    resFileList: {
      handler(val) {
        if (val.length == 0) {
          return
        }
        let sums = 0
        let percents = 0
        let statusList = []
        val.forEach((item) => {
          sums += 1
          percents += item.percentage
          statusList.push(item.status)
        })
        // 如果中包含ready的数据表示上传尚未成功。
        if (statusList.filter((item) => item == 'ready').length != 0) {
          this.$nextTick(() => {
            this.uploadStatus.isUploading = this.hide && this.showUploadStatus
            this.uploadStatus.uploadPercent = parseFloat(
              (percents / sums).toFixed(0)
            )
          })
        } else {
          // 否则表示都上传完毕（没有统计是否都成功）
          this.$nextTick(() => {
            this.uploadStatus.isUploading = false
            this.uploadStatus.uploadPercent = 0
          })
        }
      },
      deep: true
    }
  },
  computed: {
    cResFileList() {
      return this.resFileList.filter((item) => {
        return item.modifyStatus < 3
      })
    },
    acceptStrings() {
      return this.acceptString.split(',').map(el=>{
        return el.charAt(0) === '.' ? el : `.${el}` 
      }).join(',')
    },
  },
  created() {
    localStorage.debug = 'ali-oss'
    this.getListInit()
  },
  methods: {
    // 视频展示弹窗-关闭处理
    closeMovieDialog() {
      this.open = false
      // 视频播放器组件，在弹窗关闭时，需要暂停播放
      this.$refs.videoPlayer.player.pause()
    },
    // 视频展示弹窗-展示处理
    handleShowPlayer(attachment) {
      this.videoForm.video = attachment
      this.playerOptions.sources[0].src = attachment.filePath
      this.open = true
      this.$refs.videoPlayer.player.load()
      this.dialogTitle = ''
    },
    // 视频播放器-视频旋转处理
    changeDirecation() {
      if (this.directionFlag) {
        this.direcation += 90
        this.$refs.videoPlayer.$refs.video.style.transform =
          ' rotate(' + this.direcation + 'deg)'
      } else {
        this.msgError('请等待视频加载完毕')
      }
    },
    // 视频播放器-加载成功后回调
    playerReady(val) {
      // 视频旋转功能开启
      this.directionFlag = true
      // 视频播放器组件样式处理
      this.$refs.videoPlayer.$refs.video.style.transform =
        ' rotate(' + this.direcation + 'deg)'
      // 视频播放器-添加视频旋转按钮
      const menuButton = videojs.getComponent('MenuButton')
      const myMenuButton = new menuButton(this.$refs.videoPlayer.player)
      myMenuButton.controlText('旋转')
      myMenuButton.addClass('my-menu-button')
      myMenuButton.buildCSSClass('my-menu-button')
      // 视频旋转按钮-点击事件
      myMenuButton.on('click', ($event) => {
        this.changeDirecation()
      })
      this.$refs.videoPlayer.player.myMenuButton = myMenuButton
      this.$refs.videoPlayer.player.controlBar.addChild(myMenuButton, {})
    },
    // 页面处理tip显示
    acceptFormatter(val) {
      let acceptString = val.replace(',', ' ')
      return acceptString
    },
    // 初始化文件列表
    getListInit() {
      if (this.fileList.length > 0) {
        // el-upload组件文件列表，防止未知错误，请勿删除
        this.disFileList = this.fileList.map((item) => {
          let array = item.fileName.split('.')
          let imgtype = array[array.length - 1]
          return {
            id: item.id,
            suffix: imgtype,
            fileName: item.fileName,
            filePath: item.filePath,
            fileSize: item.fileSize,
            modifyStatus: 0
          }
        })
        // 获取文件列表并渲染组件内文件列表数据
        this.resFileList = this.fileList.map((item) => {
          let array = item.fileName.split('.')
          let imgtype = array[array.length - 1]
          return {
            resourceId: item.id,
            id: item.id,
            suffix: imgtype.toLowerCase(),
            fileName: item.fileName,
            resourceName: item.fileName,
            filePath: item.filePath,
            fileSize: item.fileSize,
            createUserName: item.createUserName,
            createTime: item.createTime,
            percentage: 100,
            status: 'success',
            publishUserName: item.publishUserName,
            modifyStatus: item.modifyStatus || 0
          }
        })
      } else {
        this.$nextTick(() => {
          this.resFileList = []
          this.disFileList = []
        })
      }
    },
    // 关闭图片预览
    handleImageViewerClose() {
      this.imagePreviewVisible = false
      this.imagePreviewIndex = 0
    },
    // 文件删除
    handleDelete(val) {
      this.$emit('deleteFile', val)
      for (let i = 0; i < this.resFileList.length; i++) {
        if(val.resourceId != undefined){
          if (this.resFileList[i].resourceId === val.resourceId) {
          this.resFileList.splice(i, 1)
        }
        }else{
          if (this.resFileList[i].fileUid === val.fileUid) { 
          this.resFileList.splice(i, 1) 
        }
        }
      }
      // 文件删除后，需要更新业务需要的文件列表数据
      let array = []
      this.resFileList.forEach((item) => {
        array.push({
          id: item.id,
          fileExt: item.suffix,
          fileName: item.fileName,
          filePath: item.filePath,
          fileSize: item.fileSize,
          modifyStatus: item.modifyStatus
        })
      })

      this.$emit('change', array)
    },
    /** 文件个数超出 */
    handleExceed() {
      this.$message.error(`最多上传${this.limit}个文件`)
    },
    // 文件上传拦截
    handleBeforeUpload(file) {
      let sizeType = this.fileSizeFormatter(file.size)
      if(sizeType.slice(-2)==this.limitSize.slice(-2)){
        this.limitSize.slice(0,-2)<sizeType.slice(0,-2)
        this.$message.error(file.name + `文件过大，请上传${this.limitSize}以下文件`)
        return false
      }else if(this.limitSize.slice(-2)=='MB'&& sizeType.slice(-2)=='GB'){ 
        this.$message.error(file.name + `文件过大，请上传${this.limitSize}以下文件`)
        return false
      }else if(this.limitSize.slice(-2)=='KB'&&(sizeType.slice(-2)=='GB'|| sizeType.slice(-2)=='MB')){ 
        this.$message.error(file.name + `文件过大，请上传${this.limitSize}以下文件`)
        return false
      }else if(this.limitSize.slice(-2)!='KB'&&this.limitSize.slice(-2)!='MB'&&this.limitSize.slice(-2)!='GB'){
        this.$message.error(file.name + `文件过大，请上传${this.limitSize}以下文件`)
        return false
      }
      if (sizeType === '未知大小') {
        this.$message.error(file.name + '文件过大，请上传4G以下文件')
        return false
      }
      if (sizeType === '文件太小') {
        this.$message.error(file.name + '文件过小，请检查后重新上传')
        return false
      }
      let suffixArray = file.name.split('.')
      let imgtype = suffixArray[suffixArray.length - 1]
      // 判断文件类型
      if (!this.acceptString.toUpperCase().match(imgtype.toUpperCase())) {
        // this.$message.error(file.name + '文件不符合上传条件')
        this.$message.error(`请上传符合${this.acceptString}类型的文件`)
        return false
      }
      let array = this.resFileList.filter((item) => item.modifyStatus != 3)
      if (array.length >= Number(this.limit)) {
        Message.closeAll()
        Message.error(`最多上传${this.limit}个文件`)
        return false
      } 
    },
    // 执行上传
    handleUploadFile(option) {
      let _self = this
      // 文件后缀截取
      let suffixArray = option.file.name.split('.')
      let imgtype = suffixArray[suffixArray.length - 1]
      this.resFileList.push({
        fileName: option.file.name,
        fileSize: option.file.size,
        fileUid: option.file.uid,
        suffix: imgtype.toLowerCase(),
        percentage: 0,
        modifyStatus: 1,
        status: 'ready',
        fileLoading: true
      })
      // 文件上传中禁止点击保存按钮，用于业务的新增和修改页面，对“保存”“保存并送审”的处理
      bus.$emit('btnDisabled', 'loading')
      // 文件读取操作，用于获取文件md5操作
      var dataFile = option.file
      if (option.file.size > 1000000000) {
        const sliceLength = 10
        const chunkSize = Math.ceil(dataFile.size / sliceLength)
        const fileReader = new FileReader()
        const getmd5 = new SparkMD5()
        let index = 0
        const loadFile = () => {
          const slice = dataFile.slice(index, index + chunkSize)
          fileReader.readAsBinaryString(slice)
        }
        loadFile()
        fileReader.onload = (e) => {
          getmd5.appendBinary(e.target.result)
          if (index < dataFile.size) {
            index += chunkSize
            loadFile()
          } else {
            _self.ossStsRegisterApi(option, getmd5.end())
          }
        }
        fileReader.onerror = function (e) {
          for (let i = 0; i < _self.resFileList.length; i++) {
            if (_self.resFileList[i].fileUid == option.file.uid) {
              _self.resFileList.splice(i, 1)
            }
          }
          _self.$message.error('文件读取失败，请重新选择')
          // 解除对“保存”“保存并送审”的限制点击操作
          bus.$emit('btnDisabled', 'done')
        }
      } else {
        // 文件读取
        var fileReader = new FileReader()
        var spark = new SparkMD5.ArrayBuffer()
        // 获取文件二进制数据
        fileReader.readAsArrayBuffer(dataFile)
        fileReader.onload = function (e) {
          spark.append(e.target.result)
          // 获取文件文件md5
          let md5 = spark.end()
          _self.ossStsRegisterApi(option, md5)
        }
        fileReader.onerror = function (e) {
          for (let i = 0; i < _self.resFileList.length; i++) {
            if (_self.resFileList[i].fileUid == option.file.uid) {
              _self.resFileList.splice(i, 1)
            }
          }
          _self.$message.error('文件读取失败，请重新选择')
          // 解除对“保存”“保存并送审”的限制点击操作
          bus.$emit('btnDisabled', 'done')
        }
      }
    },
    // oss上传第一步需要，调用后端接口，判断文件是否存在
    ossStsRegisterApi(option, fileMd5) {
      // 文件后缀获取
      let suffixArray = option.file.name.split('.')
      let imgtype = suffixArray[suffixArray.length - 1]
      // 接口参数拼接
      let params = {
        fileName: option.file.name,
        extName: imgtype,
        fileSize: option.file.size,
        hashcode: fileMd5
      }
      ossStsRegister(params).then((res) => {
        if (res.code === 200) {
          const response = res.data
          // 文件不存在需重新上传
          if (response.existed != 1) {
            this.resFileList.forEach((item) => {
              if (item.fileUid == option.file.uid) {
                item.resourceId = response.resourceId
                item.resourceName = response.resourceName
                item.fileMd5 = response.fileMd5
                item.fileLoading = false
                item.filePath = response.uri
                item.file = option
              }
            })
            this.getFileSize(response, option)
          }
          // 文件存在
          else {
            this.resFileList.forEach((item) => {
              if (item.fileUid == option.file.uid) {
                item.resourceId = response.resourceId
                item.resourceName = response.resourceName
                item.fileMd5 = response.fileMd5
                item.fileLoading = false
                item.filePath = response.uri
                item.file = option
                item.percentage = 100
                item.modifyStatus = 1
                item.status = 'success'
              }
            })

            let readyArray = this.resFileList.filter(
              (item) => item.status == 'ready'
            )
            if (readyArray.length == 0) {
              bus.$emit('btnDisabled', 'done')
            }
            let array = []
            this.resFileList.forEach((item) => {
              let params = {
                fileExt: item.suffix,
                fileName: item.fileName,
                filePath: item.filePath,
                fileSize: item.fileSize,
                modifyStatus: item.modifyStatus
              }
              if (item.id != undefined) {
                params.id = item.id
              }
              array.push(params)
            })
            if (this.contractType) {
              this.$emit('change', array[0])
            } else {
              this.$emit('change', array)
            }
          }
        } else {
          for (let i = 0; i < this.resFileList.length; i++) {
            if (this.resFileList[i].fileUid == option.file.uid) {
              this.resFileList.splice(i, 1)
            }
          }
          Message.error({
            showClose: true,
            message: option.file.name + '上传失败, 请重试',
            type: 'error'
          })
        }
      }).catch(error=>{
        for (let i = 0; i < this.resFileList.length; i++) {
            if (this.resFileList[i].fileUid == option.file.uid) {
              this.resFileList.splice(i, 1)
            }
          }
      })
    },
    // 根据文件实际大小，确定文件应该调用哪个oss方法上传（根据实际情况可进行调整）
    // 阿里云sdk地址：https://help.aliyun.com/document_detail/383952.html
    getFileSize(response, option) {
      let _self = this
      // 文件大于100k小于等于100m
      if (option.file.size > 10000000 && option.file.size <= 100000000) {
        _self.getMultipartOssClient(response, option.file)
      }
      // 文件小于100k
      else if (option.file.size > 0 && option.file.size <= 10000000) {
        _self.getOssClient(response, option.file)
      }
      // 文件大于100m
      else {
        _self.getBigMultipartOssClient(response, option.file)
      }
    },
    // 小文件（oss文件鉴权&简单上传）
    getOssClient(response, params) {
      // oss初始化
      const client = new OSS({
        region: 'oss-cn-zhangjiakou',
        accessKeyId: response.accessKey,
        accessKeySecret: response.accessSecret,
        stsToken: response.accessToken,
        bucket: response.bucketName
      })
      this.putFile(response, client, params)
    },
    // 小文件上传
    putFile(response, client, params) {
      let _self = this
      client
        .put(response.resourceName, params)
        .then(function (result) {
          // 上传成功
          if (result.res.status == 200) {
            _self.handleSuccess(result.name)
          } else {
            _self.handleError(result, params, 'little')
          }
        })
        .catch(function (err) {
          // 上传失败
          _self.handleError(err, params, 'little')
        })
    },
    // 中文件（oss文件鉴权&分片上传）
    getMultipartOssClient(response, params) {
      // oss初始化
      const client = new OSS({
        region: 'oss-cn-zhangjiakou',
        accessKeyId: response.accessKey,
        accessKeySecret: response.accessSecret,
        stsToken: response.accessToken,
        bucket: response.bucketName
      })
      this.putMultipartFile(response, client, params)
    },
    // 中文件上传
    putMultipartFile(response, client, params) {
      let _self = this
      // 调用上传方法
      client
        .multipartUpload(response.resourceName, params, {
          progress: function (p, cpt, res) {
            _self.resFileList.forEach((item) => {
              if (item.status !== 'success' && item.fileUid == cpt.file.uid) {
                _self.$nextTick(() => {
                  item.abortCheckpoint = cpt
                  let percentage = p * 100
                  item.percentage = percentage.toFixed(0)
                })
              }
            })
          }
        })
        .then(function (result) {
          // 上传成功
          if (result.res.status == 200) {
            _self.handleSuccess(result.name)
          } else {
            _self.handleError(result, params)
          }
        })
        .catch(function (err) {
          // 上传失败
          _self.handleError(err, params)
        })
    },
    // 大文件（oss文件鉴权&分片上传）
    getBigMultipartOssClient(response, params) {
      let _self = this
      // oss初始化
      const client = new OSS({
        region: 'oss-cn-zhangjiakou',
        accessKeyId: response.accessKey,
        accessKeySecret: response.accessSecret,
        stsToken: response.accessToken,
        bucket: response.bucketName
      })
      // 保存连接client用于断点续传
      _self.resFileList.forEach((item) => {
        if (item.fileUid === params.uid) {
          item.client = item.client
        }
      })
      this.putBigMultipartFile(response, client, params)
    },
    // 大文件上传
    putBigMultipartFile(response, client, params) {
      let _self = this
      // 调用上传方法
      client
        .multipartUpload(response.resourceName, params, {
          parallel: 4,
          partSize: 1024 * 1024,
          progress: function (p, cpt, res) {
            _self.resFileList.forEach((item) => {
              if (item.fileUid === cpt.file.uid) {
                _self.$nextTick(() => {
                  item.abortCheckpoint = cpt
                  let percentage = p * 100
                  item.percentage = percentage.toFixed(0)
                })
              }
            })
          }
        })
        .then(function (result) {
          // 上传成功
          if (result.res.status === 200) {
            _self.handleSuccess(result.name)
          } else {
            _self.handleError(result, params, 'big')
          }
        })
        .catch(function (err) {
          // 上传失败
          _self.handleError(err, params, 'big')
        })
    },
    // 文件上传失败回调
    handleError(err, file, type) {
      // 小文件
      if (type === 'little') {
        for (let i = 0; i < this.resFileList.length; i++) {
          if (this.resFileList[i].fileUid === file.uid) {
            this.resFileList.splice(i, 1)
          }
        }
        this.$message.error('上传失败, 请重试')
      }
      // todo 中文件
      else if (type === 'middle') {
        this.handleResumeUpload(file)
      }
      // todo 大文件
      else if (type === 'big') {
      }
      // todo 断点续传后的上传失败
      else {
        for (let i = 0; i < this.resFileList.length; i++) {
          if (this.resFileList[i].fileUid === file.uid) {
            this.resFileList.splice(i, 1)
          }
        }
        this.$message.error('上传失败, 请重试')
      }

      let readyArray = this.resFileList.filter(
        (item) => item.status === 'ready'
      )
      if (readyArray.length === 0) {
        bus.$emit('btnDisabled', 'done')
      }
    },
    // 由于某些原因导致上传失败断点续传
    handleResumeUpload(params) {
      let listItem = null
      let client = null
      const _self = this
      // 找到失败的那一项
      _self.resFileList.forEach((item) => {
        if (item.fileUid === params.uid) {
          listItem = item
          client = item.client
        }
      })
      // 执行重新上传
      client
        .multipartUpload(listItem.resourceName, params, {
          checkpoint: listItem.abortCheckpoint,
          progress: function (p, cpt, res) {
            _self.resFileList.forEach((item) => {
              if (item.fileUid === cpt.file.uid) {
                item.abortCheckpoint = cpt
                let percentage = p * 100
                item.percentage = percentage.toFixed(0)
              }
            })
          }
        })
        .then(function (result) {
          // 上传成功
          if (result.res.status === 200) {
            _self.handleSuccess(result.name)
          } else {
            _self.handleError(result, params, 'little')
          }
        })
        .catch(function (err) {
          // 上传失败
          _self.handleError(err, params, 'middle')
        })
    },
    // 文件上传成功回调
    handleSuccess(resourceName) {
      let _self = this
      _self.resFileList.filter(i=> i.resourceName).forEach((item) => {
        if (item.resourceName.search(resourceName) !== -1) {
          _self.ossRegisterSuccess(item.resourceId)
        }
      })
    },
    // 文件上传成功-后台通用回调
    ossRegisterSuccess(resourceId) {
      let _self = this
      ossStsRegisterCallback(resourceId).then((res) => {
        if (res.code === 200) {
          // 文件列表处理，设置文件上传状态
          _self.resFileList.forEach((item) => {
            if (item.resourceId === resourceId) {
              item.status = 'success'
              item.percentage = 100
            }
          })
          // 判断文件列表中是否有文件还未上传完，若没有则恢复“保存”“保存并送审”按钮可点击状态
          let readyArray = _self.resFileList.filter(
            (item) => item.status === 'ready'
          )
          if (readyArray.length === 0) {
            bus.$emit('btnDisabled', 'done')
          }
          // 文件上传成功后，拼接数据返回页面
          let array = []
          _self.resFileList.forEach((item) => {
            let params = {
              fileExt: item.suffix,
              fileName: item.fileName,
              filePath: item.filePath,
              fileSize: item.fileSize,
              modifyStatus: item.modifyStatus
            }
            if (item.id !== undefined) {
              params.id = item.id
            }
            array.push(params)
          })
          // 合同相关特殊处理，返回第一条给页面
          if (this.contractType) {
            this.$emit('change', array[0])
          } else {
            this.$emit('change', array)
          }
        }
      })
    },
    // 文件下载
    handleDownload(val) {
      this.downloadFileBlob(val.filePath, val.fileName, val.fileSize)
    },
    // 文件预览
    handleImagePreview(val) {
      let newfilePath =val.filePath.split("http://file.dlscpx.com/").join("https://mm.huizhuyun.com/filesc/")
      // 图片类文件预览处理
      if (
        this.acceptImageTypeString.toUpperCase().match(val.suffix.toUpperCase())
      ) {
        this.imagePreviewList = []
        // 将图片类型的数据筛选出来单独缓存，用来图片预览时的上下翻页。
        this.resFileList.forEach((file) => {
          if (
            this.acceptImageTypeString
              .toUpperCase()
              .match(file.suffix.toUpperCase())
          ) {
            let newpath =file.filePath.split("http://file.dlscpx.com/").join("https://mm.huizhuyun.com/filesc/")
            this.imagePreviewList.push(newpath)
          }
        })
        // 图片预览
        this.imagePreviewIndex = this.imagePreviewList.findIndex((filePath) => {
          return filePath === newfilePath
        })
        debugger
        if (this.imagePreviewIndex !== -1) {
          this.imagePreviewVisible = true
        }
      }
      // 文档类文件预览处理-office相关
      else if (
        this.officePreviewType.toUpperCase().match(val.suffix.toUpperCase())
      ) {
        window.open(
          'https://view.officeapps.live.com/op/view.aspx?src=' + newfilePath
        )
      }
      // PDF类文件预览处理
      else if (val.suffix.toUpperCase().match('PDF')) {
        fetch(newfilePath, {
        method: 'get',
        responseType: 'arraybuffer',
      })
        .then(function (res) {
          if (res.status !== 200) {
            return res.json()
          }
          return res.arrayBuffer()
        })
        .then((blobRes) => {
          // 生成 Blob 对象，设置 type 等信息
          const e = new Blob([blobRes], {
            type: 'application/pdf',
            'Content-Disposition':'inline'
          })
          // 将 Blob 对象转为 url
          const link = window.URL.createObjectURL(e)//
           window.open(link)
        }).catch(err => {
          console.error(err)
        })
      }
      // 视频类文件预览处理-MP4
      else if (val.suffix.toUpperCase() === 'MP4') {
        this.handleShowPlayer(val)
      } else {
        // pass
      }
    },
    // 电脑系统判断
    checkPcSystem() {
      if (/macintosh|mac os x/i.test(navigator.userAgent)) {
        return 'mac'
      } else if (/windows|win32/i.test(navigator.userAgent)) {
        return 'windows'
      }
    },
    // 文件大小转换
    fileSizeFormatter(size, type) {
      if (!size) {
        if (type == '1') {
          return ''
        } else {
          return '文件太小'
        }
      }
      // 电脑系统判断，因为mac和win的文件大小计算规则不同
      let checkSystem = this.checkPcSystem()
      var num = checkSystem == 'mac' ? 1000.0 : 1024.0 //byte
      if (Number(size) - num <= 0) return size + 'B'
      if (Number(size) - Math.pow(num, 2) <= 0)
        return (Number(size) / num).toFixed(2) + 'K' //kb
      if (Number(size) - Math.pow(num, 3) < 0)
        return (Number(size) / Math.pow(num, 2)).toFixed(2) + 'M' //M
      if (Number(size) - Math.pow(num, 3) * 4 <= 0)
        return (Number(size) / Math.pow(num, 3)).toFixed(2) + 'G' //G·
      return '未知大小'
    },
    // 获取文件图标
    getIconimage(suffix) {
      if (this.acceptStringIcon.toUpperCase().match(suffix.toUpperCase())) {
        if (suffix.toUpperCase() === 'PDF') {
          return '#icon-' + 'PDF-copy'
        } else if (suffix.toUpperCase() === 'ZIP') {
          return '#icon-' + 'zip'
        } else if (suffix.toUpperCase() === '7Z') {
          return '#icon-a-7Z'
        } else if (suffix.toUpperCase() === 'WMA') {
          return '#icon-' + 'wma'
        } else {
          return '#icon-' + suffix.toUpperCase()
        }
      } else {
        return '#icon-other'
      }
    },
    // 上传成功回调
    handleUploadSuccess(response) {
      if (this.isShowSuccessTip) {
        // pass
      }
      if (response.code === 200) {
        // 将图片类型的数据筛选出来单独缓存，用来图片预览时的上下翻页。
        const path = response.data.link
        if (path) {
          this.acceptImageType.forEach((suffix) => {
            if (path.endsWith(suffix)) {
              // pass
            }
          })
        }
        if (response.data.link !== undefined) {
          // pass
        } else if (this.listButton) {
          this.$message.success('上传成功')
        }
      } else {
        // this.$message.error('文件上传失败，请重试！')
        // pass
      }
    },
    // 文件下载方法
    downloadFileBlob(path, name, size) {
      let _self = this
      if(new URL(path).protocol=== "http:"){
        let newpath =path.split("http://file.dlscpx.com/").join("https://mm.huizhuyun.com/filesc/")
        const a = document.createElement('a')
          a.style.display = 'none'
          a.href = newpath
          a.download = name
          a.target = '_blank'
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
      }else{ 
      let checkSystem = this.checkPcSystem()
      var num = checkSystem == 'mac' ? 1000.0 : 1024.0 //byte
      if (
        Number(size) - Math.pow(num, 3) * 4 <= 0 &&
        (Number(size) / Math.pow(num, 2)).toFixed(2) - 100 > 0
      ) {
        this.$message.info(name + '文件超过100M，下载需等待')
      }
      const xhr = new XMLHttpRequest()
      xhr.open('get', path)
      xhr.responseType = 'blob'
      xhr.send()
      xhr.onload = function () {
        if (this.status === 200 || this.status === 304) {
          // 如果是IE10及以上，不支持download属性，采用msSaveOrOpenBlob方法，但是IE10以下也不支持msSaveOrOpenBlob
          if ('msSaveOrOpenBlob' in navigator) {
            navigator.msSaveOrOpenBlob(this.response, name)
            return
          }
          const url = URL.createObjectURL(this.response)
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = url
          a.download = name
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          URL.revokeObjectURL(url)
        }
      }
      xhr.onerror = function (e) {
        _self.$message.error('文件下载失败，请重新尝试')
      }
    }
    },
    /**
     * table样式附件列表方法
     */
    operationClick(command) {
      const name = command.name
      const row = command.row
      if (name == '预览') {
        this.handleImagePreview(row)
      } else if (name == '下载') {
        this.handleDownload(row)
      } else if (name == '删除') {
        this.handleDelete(row)
      }
    },
    selectionChange(vals) {
      this.selectionData = vals
    },
    batchDown() {
      if (this.selectionData.length == 0) {
        this.$message.warning('请选择附件')
        return
      } else {
        this.$emit('downloadBatch', this.selectionData)
        this.$refs.tableRef.clearSelection()
      }
    }
  }
}
</script>


<style lang="scss" scoped>
a {
  cursor: pointer;
  color: #409eff;
}

.fileIcon{
    color: #409eff;
    margin: 0 5px 0 10px;
  }
a:hover {
  color: #409eff !important;
}

.icon {
  width: 100%;
  height: 100%;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.detail-info-attachment {
  // padding: 15px 0 15px 0;
}

.detail-info-attachment-title {
  font-size: 16px;
  color: #000;
  padding-bottom: 10px;
}

.detail-info-attachment-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}

.detail-info-attachment-item {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  min-height: 70px;
  border-radius: 12px;
  background-color: #f7f7f7;
  padding: 0 18px;
  margin: 5px 0;
  .el-loading-mask {
    line-height: 20px !important;
    background-color: #f5f7fa82 !important;
  }
}

.attachment-item-small {
  width: 95%;
}

.attachment-item-icon {
  .icon{
    width: 48px;
    height: 48px;
    flex-shrink: 0;
  }
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 12px;
  font-size: 14px;
  text-align: center;
  line-height: 48px;
  margin-right: 5px;
  width: 70%;
}

.attachment-item-name {
  margin-left: 24px;
  margin-right: 14px;
  color: #383d4a;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  max-width: 600px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.attachment-item-info {
  margin-left: 24px;
  margin-right: 14px;
  color: #383d4a;
  font-size: 16px;
}

.attachment-item-progress {
  position: absolute;
  right: 30px;
  display: flex;
}

.attachment-item-status {
  // position: absolute;
  // right: 10px;
  display: flex;

  i {
    color: #67c23a;
  }
}

.attachment-item-link-download {
  // position: absolute;
  // right: 80px;
  white-space: nowrap;
  margin: 0 5px;
}

.attachment-item-link-preview {
  // position: absolute;
  // right: 130px;
  margin: 0 5px;
  white-space: nowrap;
}

.attachment-item-link-delete {
  // position: absolute;
  // right: 30px;
  margin: 0 5px;
  white-space: nowrap;
}

.table-style {
  display: inline-flex;
}

.loading-mask {
  line-height: 20px;
  background-color: #f5f7fa82;
}
.el-upload__tip {
     margin-top: -7px;
     word-wrap:break-word;
     line-height:20px;
     margin: 10px;
    }
   ::v-deep .el-upload-dragger{
      overflow: visible;
      line-height: 40px;
      height: auto;
    }
</style>
