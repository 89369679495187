<template>
  <div class="container">
    <div class="address-content">
      <div class="grid-content" @click="add">
        <div class="icon"><img src="./images/u13412.svg" alt=""></div>
        <div class="text-tit">添加新地址</div>
        <div style="text-align:center;line-height:20px;margin-top:10px">您已经创建{{ tableData.length }}个收件地址，最多可创建10 个</div>
      </div>

      <div class="grid-content grid-content1" v-for='item in tableData' :key="item.id" @click="choseOne(item)"
        :style="{ 'border': choseIdx === item.id ? '3px dashed #FF7826' : '3px solid #F2F6FC' }">
        <div class="consignee-box"> {{ item.name }}</div>
        <div class="consignee-box consignee-tel">
          <div class="consignee-box consignee-tel">{{
            item.contactPhone.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2")
          }}
          </div>
          <div class="consignee-box consignee-addrrss" style="line-height:20px">
            {{ item.province }}{{ item.city }}{{ item.district }}{{ item.address }}</div>
        </div>
        <div style="height:30px"></div>
        <div class="consignee-box consignee-menu">
          <div class="flex-box" @click.stop="selectAddress(item)"> <i
              :class="['el-icon-success', 'radioIcon', item.isDefault == 1 ? 'active' : '']"></i> 设为默认地址</div>
          <div class="flex-box">
            <div class="btn" @click.stop="edit(item.id)">修改</div>
            <div class="btn" @click.stop="del(item.id)">删除</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <baiduAddress></baiduAddress> -->
    <el-dialog append-to-body width="40%" :before-close="handleClosedialogFormVisible"
      :visible.sync="dialogFormVisible">
      <div v-if="dialogFormVisible">
        <div class="dialog-title">{{ dialogConfig.title }}地址</div>
        <el-form :model="form" :rules="rules" ref="formaddress">
          <el-form-item label="收货人" prop="name" :label-width="formLabelWidth">
            <el-input v-model="form.name" placeholder="请填写收货人" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="联系方式" prop="contactPhone" :label-width="formLabelWidth">
            <el-input v-model="form.contactPhone" placeholder="请填写联系方式" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item prop="province" label="直接搜索" :label-width="formLabelWidth">
            <vue-address @change="handlerChange" @clickaddressbtn="innerVisible = true" :nameStrSource="addressDetails"
              @changeaddrs="changeaddrs"></vue-address>
          </el-form-item>
          <el-form-item label="详细地址" prop="address" :label-width="formLabelWidth">
            <el-input v-model="form.address" type="textarea" :rows="6" maxlength="140" show-word-limit
              placeholder="请输入内容" autocomplete="off">
            </el-input>
            <div @click="addressRead" style="position:relative;top:-40px; left:20px;cursor: pointer;">
              <svg class="ali-icon small" aria-hidden="true">
                <use xlink:href="#icon-ocryinshuawenzishibie"></use>
              </svg>文字识别
            </div>
          </el-form-item>
          <el-form-item :label-width="formLabelWidth">
            <el-checkbox @change="changestatus" :checked="form.isDefault == 1" v-model="checked">设为默认收货地址</el-checkbox>
          </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="handleSure"  :disabled='buttonloading'>确 定</el-button>
        </div>
        <el-dialog append-to-body width="60%" :before-close="handleCloseinnerVisible" :visible.sync="innerVisible">
          <div class="dialog-title">选择地址</div>
          <baiduAddress @change='changeaddress'></baiduAddress>
        </el-dialog>
      </div>
    </el-dialog>
  </div>
</template>

<script >
import mDialog from "@/components/dialog/src/mDialog.vue";
import TablePage from '@/components/tablePage/table-page.vue';
import vueAddress from '@/components/address/address.vue'
import baiduAddress from '@/components/address/baiduaddress.vue'
import { addressPage, addressSave, addressDetail, addressUpdate, addressRemove, addressRead } from '@/api/mySupplier/myAddress.js'
export default {
  name: "", // 角色管理
  components: { mDialog, TablePage, vueAddress, baiduAddress },
  data () {
    return {
      buttonloading:false,
      innerVisible: false,
      checked: false,
      province: '',
      city: '',
      district: '',
      detail: '',
      addressDetails: [],
      rules: {
        name: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 2, max: 16, message: '长度在 2 到 16 个字符', trigger: 'blur' }
        ],
        contactPhone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          // 方法一：
          {
            validator: (rule, value, cb) => {
              if (/^1[3-9]\d{9}$/.test(value)) {
                cb()
              } else {
                cb(new Error('手机号格式错误'))
              }
            }
          },
        ],
        province: [{ required: true, message: '请选择地址' },],
        address: [{ required: true, message: '请输入详细地址' },],
      },
      selectId: null,
      disabled: false,
      dialogConfig: {
        title: "",
        width: "600px",
        top: "7%",
        btnText: []
      },
      page: {
        pageSize: 20,
        currentPage: 0,
        total: 100
      },
      tableData: [],
      dialogFormVisible: false,
      form: {
        name: '',
        contactPhone: '',
        province: '',
        city: '',
        district: '',
        address: '',
        isDefault: 0
      },
      province: '',
      city: '',
      district: '',
      formLabelWidth: '120px',
      choseIdx: ""
    };
  },
  watch: {
    "dialogConfig.title": function (n) {
      if (n == "详情") {
        this.disabled = true
        this.dialogConfig.btnText = ["关闭"]
      } else {
        this.disabled = false
        this.dialogConfig.btnText = ["取消", "保存"]
      }
    }
  }, mounted () {
    const _this = this
  },
  mounted () {
    this.getList()
  },
  methods: {
    changeaddrs (e) {
      this.form.provinceCode = e.addrs[0];
      this.form.cityCode = e.addrs[1]
      this.form.districtCode = e.addrs[2]
    },
    addressRead () {
      addressRead(this.form.address).then(res => {
        if (res.success) {
          this.form.province = res.data.province.replace(/\r\n/g, "").replace(/\n/g, "");
          this.form.city = res.data.city.replace(/\r\n/g, "").replace(/\n/g, "")
          this.form.district = res.data.district.replace(/\r\n/g, "").replace(/\n/g, "")
          this.form.provinceCode = '';
          this.form.cityCode = ''
          this.form.districtCode = ''
          this.form.address = res.data.address.replace(/\r\n/g, "").replace(/\n/g, "")
          this.form.name = res.data.name.replace(/\r\n/g, "").replace(/\n/g, "")
          this.form.contactPhone = res.data.contactPhone.replace(/\r\n/g, "").replace(/\n/g, "")
           setTimeout(()=>{
                this.$set(this.addressDetails, 0,this.form.province);
                this.$set(this.addressDetails, 1,this.form.city);
                this.$set(this.addressDetails, 2, this.form.district);
            },200)
        } 
        // else {
        //   this.$message.warning(res.msg);
        // }
      })
    },
    choseOne (item) {
      this.choseIdx = item.id
      this.$emit('input', item.id)
      this.$emit('chose', item)
      console.log(123)
    },
    getList () {
      addressPage().then(res => {
        this.tableData = res.data.records
        if(this.tableData.length>0){
          this.choseOne (this.tableData[0])
        }
      })
    },
    changestatus (e) {
      if (e) {
        this.form.isDefault = 1
      } else {
        this.form.isDefault = 0
      }
    },
    handleSure () {
      this.$refs['formaddress'].validate((valid) => {
        if (valid) {
          this.buttonloading=true
            setTimeout(()=>{
                this.buttonloading=false
            },1000)
          if (this.dialogConfig.title == "新增") {
            addressSave(this.form).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '新增成功!'
                });
                this.dialogFormVisible = false
                this.getList()
              } else {
                this.$message({
                  type: 'info',
                  message: '新增失败'
                });
              }
            }).catch(() => {

            })
          }
          if (this.dialogConfig.title == "修改") {
            addressUpdate(this.form).then(res => {
              if (res.code == 200) {
                this.$message({
                  type: 'success',
                  message: '修改成功!'
                });
                this.dialogFormVisible = false
                this.getList()
              } else {
                this.$message({
                  type: 'info',
                  message: '修改失败'
                });
              }
            }).catch(() => {

            })
          }
        } else {
          this.$message({
            type: 'info',
            message: '请完善信息!'
          });
          return false;
        }


      });
    },
    handlerChange (e) {
      console.log(e)
      this.form.province = e.name[0];
      this.form.city = e.name[1]
      this.form.district = e.name[2]
      this.form.provinceCode = e.code[0];
      this.form.cityCode = e.code[1]
      this.form.districtCode = e.code[2]
    },
    changeaddress (e) {
      this.$set(this.form, "address", e.addComp);
      console.log(e)
      this.form.province = e.province;
      this.form.city = e.city
      this.form.district = e.district
      this.$set(this.addressDetails, 0, e.province);
      this.$set(this.addressDetails, 1, e.city);
      this.$set(this.addressDetails, 2, e.district);
      this.handleCloseinnerVisible()
    },
    handleClosedialogFormVisible () {
      this.dialogFormVisible = false
    },
    handleCloseinnerVisible () {
      this.innerVisible = false
    },
    selectAddress (item) {
      this.form = {}
      this.form.id = item.id
      this.form.isDefault = 1
      addressUpdate(this.form).then(res => {
        if (res.code == 200) {
          this.$message({
            type: 'success',
            message: '默认地址设置成功!'
          });
          this.dialogFormVisible = false
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: '默认地址设置失败'
          });
        }
      }).catch(() => {

      })

    },
    handleSizeChange (val) {
      this.page.currentPage = 1;
      this.page.pageSize = val;
    },
    handleCurrentChange (val) {
      this.page.currentPage = val;
    },
    add () {
      if (this.tableData.length >= 10) {
        this.$message({
          type: 'info',
          message: '地址最多可创建10个'
        });
      } else {
        this.dialogConfig.title = "新增"
        this.dialogFormVisible = true
        this.form = {
          name: '',
          contactPhone: '',
          province: '',
          city: '',
          district: '',
          isDefault: 0
        }
        this.addressDetails = []
        // this.$refs.dialog.open((cancel) => {
        //     cancel();
        // });
      }

    },
    edit (id) {
      this.dialogConfig.title = "修改"
      addressDetail({ id: id }).then(res => {
        this.dialogFormVisible = true
        this.form.id = id
        this.$set(this.addressDetails, 0, res.data.province);
        this.$set(this.addressDetails, 1, res.data.city);
        this.$set(this.addressDetails, 2, res.data.district);
        this.form.name = res.data.name
        this.form.contactPhone = res.data.contactPhone
        this.form.province = res.data.province
        this.form.city = res.data.city
        this.form.provinceCode = res.data.provinceCode;
        this.form.cityCode = res.data.cityCode
        this.form.districtCode = res.data.districtCode
        this.form.district = res.data.district
        this.form.address = res.data.address
        this.form.isDefault = res.data.isDefault
      })
    },
    del (id) {
      this.handleCofirm("确定要删除该条数据吗？").then(() => {
        addressRemove({ ids: id }).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getList()
          } else {
            this.$message({
              type: 'info',
              message: '删除失败'
            });
          }
        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    // detail(){
    //     this.dialogConfig.title = "详情"
    //     this.$refs.dialog.open()
    // },
    search () {

    },
    reset () {

    },

  },
};
</script>
<style lang="scss" scoped>
.flex-box {
  display: flex;
}

.align-center {
  align-items: center;
}

.dialog-title {
  font-size: 36px;
  color: #B0B0B0;
  margin-bottom: 20px;
}

.container {
  background: #FFFFFF;
  padding: 20px;
  font-family: "微软雅黑", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  color: #333333;
  box-sizing: content-box;

  .title {
    margin-top: 42px;
    font-size: 36px;
    color: #B0B0B0
  }

  .address-content {
    display: flex;
    flex-wrap: wrap;

    .grid-content {
      margin: 10px 5px;
      box-sizing: border-box;
      position: relative;
      width: 220px;
      background: inherit;
      background-color: rgba(229, 241, 250, 0);
      box-sizing: border-box;
      border-width: 1px;
      border-style: solid;
      border-color: rgba(240, 240, 240, 1);
      border-radius: 10px;
      padding: 10px;
      font-size: 12px;
      color: #757575;
      cursor: pointer;

      &.grid-content1 {
        justify-content: space-between;
      }

      .icon {
        text-align: center;
        padding: 20px;
      }

      .text-tit {
        color: #333333;
        line-height: 10px;
        text-align: center;
      }

      .consignee-box {
        width: 100%;
        text-align: left;
        overflow-wrap: anywhere;
      }

      .consignee-name {
        color: #333333;
      }

      .consignee-menu {
        position: absolute;
        bottom: 10px;
        left: 0;
        box-sizing: border-box;
        padding: 0 10px;
        display: flex;
        justify-content: space-between;
        line-height: 15px !important;

        .radioIcon {
          line-height: 15px !important;
          margin-right: 4px;

          &.active {
            color: #FF7826;
          }
        }

        .btn {
          margin: 0 10px;
          color: #FF7826;
        }
      }
    }

  }
}
</style>